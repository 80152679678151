body {
  min-height: 50rem !important;
  padding-top: 0rem !important;
}

.header h1 {
  font-size: 500%;
  font-style: italic;
  color: rgba(65, 65, 65, 1);
}

.accordion-button {
  color: rgba(65, 65, 65, 1) !important;
}

.accordion-button:not(.collapsed) {
  background-color: #40e0d040;
}

/* Override blue arrow (when expanded) with dark arrow */
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}

.nav-link {
  color: rgba(65, 65, 65, 1);
}

.nav-link:hover {
  color: rgba(125, 125, 125, 1);
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  background-color: rgba(65, 65, 65, 1);
}
